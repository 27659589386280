import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      textAlign: "center",
    },
    header: {
      paddingBottom: theme.spacing(3),
    },
    subHeader: {
      paddingBottom: theme.spacing(6),
    },
  });
