import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "588px",
      maxWidth: "100%",
    },
    header: {
      textAlign: "center",
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.down("xs")]: {
        textAlign: "unset",
        paddingBottom: theme.spacing(5),
      },
    },
    formField: {
      paddingBottom: theme.spacing(5),
    },
    submitButton: {
      alignSelf: "center",
      minWidth: "180px",
    },
  });
