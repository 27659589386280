import React from "react";

import { Typography, WithStyles, withStyles } from "@material-ui/core";
import ButtonLink from "@src/components/ButtonLink/ButtonLink";
import { T } from "react-targem";
import { styles } from "./SubmitSuccess.styles";

interface FooterProps extends WithStyles<typeof styles> {}

class SubmitSuccess extends React.PureComponent<FooterProps> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="h2" className={classes.header}>
          <T message="Thanks for your application" />
        </Typography>
        <Typography variant="h5" className={classes.subHeader}>
          <T message="We will review your application and contact you soon." />
        </Typography>
        <ButtonLink to="/" variant="contained" color="secondary">
          <T message="Go to home page" />
        </ButtonLink>
      </div>
    );
  }
}

export default withStyles(styles)(SubmitSuccess);
