import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import PhoneInput from "@src/components/PhoneInput";

import { styles } from "./Form.styles";

interface Props extends WithStyles<typeof styles> {
  submitting: boolean;
  hasJobObjective: boolean;
  onSubmit(event: React.FormEvent): void;
}

class Form extends React.PureComponent<Props> {
  public render() {
    const { classes, submitting, hasJobObjective } = this.props;

    return (
      <form onSubmit={this.props.onSubmit} className={classes.container}>
        <Typography variant="h4" className={classes.header}>
          <T message="Apply for the job" />
        </Typography>
        <TextField
          name="firstName"
          required
          disabled={submitting}
          label={<T message="First name" />}
          className={classes.formField}
        />
        <TextField
          name="lastName"
          required
          disabled={submitting}
          label={<T message="Last name" />}
          className={classes.formField}
        />
        {hasJobObjective ? (
          <TextField
            name="jobObjective"
            required
            disabled={submitting}
            label={<T message="Job objective" />}
            className={classes.formField}
          />
        ) : null}
        <TextField
          name="email"
          type="email"
          required
          disabled={submitting}
          label={<T message="Email" />}
          className={classes.formField}
        />
        <PhoneInput name="phoneNumber" disabled={submitting} />
        <TextField
          name="linkedIn"
          disabled={submitting}
          label={<T message="LinkedIn profile" />}
          className={classes.formField}
        />
        <TextField
          name="portfolio"
          disabled={submitting}
          label={<T message="Portfolio link" />}
          className={classes.formField}
        />
        <TextField
          name="personalNote"
          disabled={submitting}
          multiline
          label={<T message="Personal note" />}
          className={classes.formField}
        />
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          disabled={submitting}
          className={classes.submitButton}>
          {submitting ? (
            <CircularProgress size="20" />
          ) : (
            <T message="Submit application" />
          )}
        </Button>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
