import { FormControl, Select, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import {
  withCountryIcons,
  WithLocaleIcons,
} from "@src/components/withLocaleIcons";
import { COUNTRIES_ISO } from "@src/config/countries";

import { styles } from "./CountriesDropdown.styles";
import DropdownItem from "./DropdownItem";

interface Props extends WithLocaleIcons, WithStyles<typeof styles> {
  iso: string;
  onChange(iso: string): void;
}

export class CountriesDropdown extends React.PureComponent<Props> {
  public render() {
    const { classes, iso } = this.props;
    return (
      <FormControl>
        <Select
          className={classes.select}
          value={iso}
          renderValue={this.renderValue}
          onChange={this.handleChange}>
          {COUNTRIES_ISO.map(this.renderDropdownItem)}
        </Select>
      </FormControl>
    );
  }

  private renderDropdownItem = (iso: string) => {
    return DropdownItem(iso, this.props.icons[iso.toLowerCase()]);
  };

  private renderValue = (value: unknown) => {
    const Icon = this.props.icons[(value as string).toLowerCase()];
    return Icon ? <Icon /> : null;
  };

  private handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const iso = event.target.value as string;
    this.props.onChange(iso);
  };
}

export default withCountryIcons(withStyles(styles)(CountriesDropdown));
