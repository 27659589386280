import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    select: {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "& svg": {
        borderRadius: "50%",
      },
    },
  });
