import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { MdPageProps } from "@src/types";
import { t } from "@src/utils/translations";

import JobOpeningMarkdownTemplate from "./JobOpeningMarkdownTemplate";

export type JobOpeningPageProps = MdPageProps<{
  markdownRemark: {
    html: string;
    frontmatter: {
      title: string;
      location: string;
    };
  };
}>;

class JobOpeningMarkdownPage extends React.PureComponent<JobOpeningPageProps> {
  public render() {
    const { markdownRemark } = this.props.data;
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Apply for the job")}
          description={t(
            "We are always happy to hear from you! For any questions or recommendations do not hesitate to contact us.",
          )}
          keywords={[t("contact us"), t("open positions")]}
        />
        <JobOpeningMarkdownTemplate
          content={markdownRemark.html}
          location={markdownRemark.frontmatter.location}
          title={markdownRemark.frontmatter.title}
        />
      </IndexLayout>
    );
  }
}

export default JobOpeningMarkdownPage;
