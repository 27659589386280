import { ListItemIcon, ListItemText, MenuItem } from "@material-ui/core";
import React from "react";

import { COUNTRIES_MAP } from "@src/config/countries";

// This must be a function!
// Otherwise MenuItem won't work with Select!
const DropdownItem = (iso: string, FlagIcon?: SvgComponent) => {
  return (
    <MenuItem key={iso} value={iso}>
      <ListItemIcon>
        {FlagIcon ? <FlagIcon style={{ borderRadius: "50%" }} /> : <span />}
      </ListItemIcon>
      <ListItemText primary={COUNTRIES_MAP[iso].name} />
    </MenuItem>
  );
};

export default DropdownItem;
