import { fetch as fetchPolyfill } from "cross-fetch";
import React from "react";

import Form from "./Form";

const POST_MESSAGE_URL =
  "https://fvd69tpyab.execute-api.eu-west-1.amazonaws.com/production/send-form";

interface FormFields {
  firstName: {
    value: string;
  };
  lastName: {
    value: string;
  };
  jobObjective?: {
    value: string;
  };
  email: {
    value: string;
  };
  phoneNumber: {
    value: string;
  };
  linkedIn: {
    value: string;
  };
  portfolio: {
    value: string;
  };
  personalNote: {
    value: string;
  };
}

interface Props {
  vacancyTitle: string;
  hasJobObjective: boolean;
  onSubmit(): void;
}

interface State {
  submitting: boolean;
}

class ApplyForJobForm extends React.PureComponent<Props, State> {
  public state: State = {
    submitting: false,
  };

  public render() {
    const { submitting } = this.state;
    return (
      <Form
        onSubmit={this.handleSubmit}
        submitting={submitting}
        hasJobObjective={this.props.hasJobObjective}
      />
    );
  }

  private setSubmitting = (submitting: boolean) => {
    this.setState({ submitting });
  };

  private postMessage = async (subject: string, text: string) => {
    try {
      return await fetchPolyfill(POST_MESSAGE_URL, {
        method: "POST",
        body: JSON.stringify({
          subject,
          text,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error((error as Error).message);
      alert("Failed to submit. Try again.");
      return;
    }
  };

  private getEmailBody = (form: HTMLFormElement & FormFields) => {
    return `
    A new CV has been received on ${new Date().toDateString()}.\n
  
      Name: ${form.firstName.value} ${form.lastName.value}\n
      Email: ${form.email.value}\n
      Job objective: ${form.jobObjective ? form.jobObjective.value : ""}\n
      Phone: ${form.phoneNumber.value}\n
      LinkedIn Profile: ${form.linkedIn.value}\n
      Portfolio: ${form.portfolio.value}\n
      Personal note: ${form.personalNote.value}\n
      CV: \n
      Cover Letter: \n
    `;
  };

  private handleSubmit = async (
    event: React.FormEvent<HTMLFormElement & FormFields>,
  ) => {
    event.preventDefault();
    this.setSubmitting(true);

    const subject = `New CV for ${this.props.vacancyTitle} position`;
    const text = this.getEmailBody(event.currentTarget);
    const response = await this.postMessage(subject, text);

    if (!response) {
      this.setSubmitting(false);
      return;
    }

    if (this.isValidResponse(response)) {
      this.setSubmitting(false);
      this.props.onSubmit();
      return;
    }

    const error = await response.json();
    console.error((error as Error).message);
    alert("Failed to submit. Try again.");
    this.setSubmitting(false);
    return;
  };

  private isValidResponse = (response: Response): boolean => {
    return response.status >= 200 && response.status < 300;
  };
}

export default ApplyForJobForm;
