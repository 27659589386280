import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-end",
      paddingBottom: theme.spacing(5),
    },
    phoneInput: {
      marginLeft: theme.spacing(3),
      flexGrow: 1,
    },
    isoCode: {
      marginRight: theme.spacing(1),
    },
  });
