import { TextField, withStyles, WithStyles } from "@material-ui/core";
import awesomePhonenumber from "awesome-phonenumber";
import React, { ChangeEvent } from "react";
import { T } from "react-targem";

import CountriesDropdown from "@src/components/CountriesDropdown";

import { styles } from "./PhoneInput.styles";

interface Props extends WithStyles<typeof styles> {
  disabled: boolean;
  name: string;
}

interface State {
  iso: string;
  inputValue: string;
}

export class PhoneInput extends React.PureComponent<Props, State> {
  public phoneInputRef = React.createRef<HTMLInputElement>();

  public state = {
    iso: "FR",
    inputValue: "+33 ",
  };

  public render() {
    const { classes, disabled, name } = this.props;
    const { iso, inputValue } = this.state;

    return (
      <div className={classes.container}>
        <CountriesDropdown iso={iso} onChange={this.handleDropdownChange} />
        <TextField
          name={name}
          type="tel"
          label={<T message="Phone number" />}
          inputRef={this.phoneInputRef}
          disabled={disabled}
          value={inputValue}
          onChange={this.handleInputChange}
          className={classes.phoneInput}
        />
      </div>
    );
  }

  private handleDropdownChange = (iso: string) => {
    this.setState(
      {
        iso,
        inputValue: `+${awesomePhonenumber.getCountryCodeForRegionCode(iso)} `,
      },
      () => {
        const input = this.phoneInputRef.current;
        if (input) {
          setTimeout(() => {
            input.focus();
            // if you change dropdown value while phone input is in focus
            // caret will be placed at the start of the phone input
            // next 2 lines place caret at the end of the input, where it supposed to be
            input.value = "";
            input.value = this.state.inputValue;
          }, 0);
        }
      },
    );
  };

  private handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: ev.currentTarget.value });
  };
}

export default withStyles(styles, { flip: false })(PhoneInput);
