import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      direction: "ltr",
      width: "100%",
      maxWidth: "792px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(11),
      paddingBottom: theme.spacing(25),
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(5),
        alignItems: "flex-start",
      },
    },
    header: {
      paddingBottom: theme.spacing(2),
    },
    locationText: {
      color: theme.palette.text.secondary,
      display: "flex",
      alignItems: "center",
      paddingBottom: theme.spacing(7),
      [theme.breakpoints.down("xs")]: {
        paddingBottom: theme.spacing(5),
      },
    },
    locationIcon: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(1),
        fontSize: "16px",
      },
    },
    content: {
      paddingBottom: theme.spacing(17),
      "& h1": theme.typography.h1,
      "& h2": theme.typography.h2,
      "& h3": theme.typography.h3,
      "& h4": theme.typography.h4,
      "& h5": theme.typography.h5,
      "& h6": theme.typography.h6,
      "& p": {
        ...theme.typography.body1,
        margin: "0 0 24px 0",
        [theme.breakpoints.down("xs")]: {
          ...theme.typography.body2,
          marginBottom: "16px",
        },
      },
      "& ul": {
        margin: 0,
        padding: theme.spacing(0, 0, 3, 0),
        listStyleType: "none",
        marginLeft: theme.spacing(2.5),
      },
      "& li": {
        ...theme.typography.body1,
        position: "relative",
        "&:before": {
          position: "absolute",
          left: "-20px",
          content: `"•"`,
          color: theme.palette.primary.main,
        },
        [theme.breakpoints.down("xs")]: {
          ...theme.typography.body2,
        },
      },
    },
  });
export { styles };
