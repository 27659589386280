import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { LocationOnOutlined } from "@material-ui/icons";
import React from "react";

import ApplyForJobForm from "@src/components/ApplyForJobForm";
import SubmitSuccess from "@src/components/ApplyForJobForm/SubmitSuccess";
import CmsContent from "@src/components/CmsContent";
import PageLayout from "@src/layouts/PageLayout";

import { styles } from "./JobOpeningMarkdown.styles";

export type MarkdownTemplateProps = {
  content: React.ReactNode;
  title: string;
  location: string;
};

interface Props extends MarkdownTemplateProps, WithStyles<typeof styles> {}

interface State {
  isFormSubmitted: boolean;
}

class JobOpeningMarkdownTemplate extends React.PureComponent<Props, State> {
  public state = {
    isFormSubmitted: false,
  };

  public render() {
    const { content, title, location, classes } = this.props;

    if (this.state.isFormSubmitted) {
      return (
        <PageLayout className={classes.container}>
          <SubmitSuccess />
        </PageLayout>
      );
    }
    return (
      <PageLayout className={classes.container}>
        <Typography variant="h3" className={classes.header}>
          {title}
        </Typography>
        <Typography variant="h5" className={classes.locationText}>
          <LocationOnOutlined className={classes.locationIcon} />
          {location}
        </Typography>
        <CmsContent content={content} className={classes.content} />
        <ApplyForJobForm
          vacancyTitle={title}
          onSubmit={this.handleSubmit}
          hasJobObjective={false}
        />
      </PageLayout>
    );
  }

  private handleSubmit = () => {
    this.setState({ isFormSubmitted: true });
  };
}

export default withStyles(styles, { flip: false })(JobOpeningMarkdownTemplate);
